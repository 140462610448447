import { AxiosError } from "axios";
import { api } from "./api";

export const getWithAuth = async (
  urlRequest: string,
  headers = {},
  extraConfig = {}
) => {
  const token = localStorage.getItem("trampay-token");
  const config = {
    ...extraConfig,
    headers: {
      ...headers,
      Authorization: `Bearer ${token}`,
    },
  };

  try {
    const response = await api.get(urlRequest, config);
    if (response.status === 200 || response.status === 201) {
      return response;
    }
  } catch (error) {
    const err = error as AxiosError;
    if (err.response?.status === 401) {
      localStorage.removeItem("trampay-token");
      window.location.href = "/";
    }

    throw error;
  }
};

export const deleteWithAuth = async (
  urlRequest: string,
  headers = {},
  data?: unknown
) => {
  const token = localStorage.getItem("trampay-token");
  const config = {
    headers: {
      ...headers,
      Authorization: `Bearer ${token}`,
    },
    data,
  };
  try {
    const response = await api.delete(urlRequest, config);
    if (response.status === 200 || response.status === 201) {
      return response;
    }
  } catch (error) {
    const err = error as AxiosError;
    if (err.response?.status === 401) {
      localStorage.removeItem("trampay-token");
      window.location.href = "/";
    }
    throw Error("Usuario nao autenticado");
  }
};

export const postWithAuth = async <T>(
  urlRequest: string,
  params: T,
  headers = {}
) => {
  const token = localStorage.getItem("trampay-token");
  const config = {
    headers: {
      ...headers,
      Authorization: `Bearer ${token}`,
    },
  };
  try {
    const response = await api.post(urlRequest, params, config);
    if (response.status === 200 || response.status === 201) {
      return response;
    }
  } catch (error) {
    const err = error as AxiosError;
    if (err.response?.status === 401) {
      localStorage.removeItem("trampay-token");
      window.location.href = "/";
    }

    throw error;
  }
};

export const patchWithAuth = async <T>(
  urlRequest: string,
  params?: T,
  headers = {}
) => {
  const token = localStorage.getItem("trampay-token");
  const config = {
    headers: {
      ...headers,
      Authorization: `Bearer ${token}`,
    },
  };
  try {
    const response = await api.patch(urlRequest, params, config);
    if (response.status === 200 || response.status === 201) {
      return response;
    }
  } catch (error) {
    const err = error as AxiosError;

    if (err.response?.status === 401) {
      localStorage.removeItem("trampay-token");
      window.location.href = "/";
    }

    throw err;
  }
};
