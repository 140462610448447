import React, { useRef, useState } from "react";
import {
  VStack,
  Heading,
  Flex,
  Badge,
  HStack,
  Box,
  SimpleGrid,
  List,
  ListItem,
  ListIcon,
  Stack,
} from "@chakra-ui/layout";
import {
  Button,
  FormControl,
  FormErrorMessage,
  Input,
  Select,
  Table,
  TableContainer,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
  useToast,
  AlertDialog,
  AlertDialogBody,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogContent,
  AlertDialogOverlay,
  useDisclosure,
  Stat,
  StatLabel,
  StatNumber,
  Popover,
  PopoverTrigger,
  Portal,
  PopoverContent,
  PopoverBody,
  PopoverHeader,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  Drawer,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
  DrawerHeader,
  DrawerBody,
  Tooltip,
  Icon,
  FormLabel,
  RadioGroup,
  Radio,
} from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";
import { Controller, useForm } from "react-hook-form";
import { z } from "zod";
import { zodResolver } from "@hookform/resolvers/zod";
import {
  Download,
  MagnifyingGlass,
  WarningCircle,
} from "@phosphor-icons/react";
import { useQuery } from "@tanstack/react-query";
import { useDeliverymansStore } from "../../store/deliveryman.store";
import { useCurrentUserStore } from "../../store/current-user.store";
import { Pagination } from "../../components/pagination";
import { LoadingLogo } from "../../components/loading";
import { formatCPF } from "../../utils/format-document";
import dayjs from "dayjs";
import { useExportCsv } from "../../hooks/deliverymans/use-export-csv";
import { TRAMPAY_COMPANY_ID } from "../../utils/constants/trampay-company-id";
import { AxiosError } from "axios";
import { AxiosDataError } from "../../dto/axios-error-dto";
import {
  DELIVERYMAN_STATUS,
  IUpdatePixError,
  IUpdatePixResponse,
  deliverymanStatusTextMap,
} from "../../dto/deliveryman-dto";
import { getWithAuth, patchWithAuth } from "../../services/basicService";
import { CompanyDto } from "../../dto/company-dto";
import { DayPicker } from "react-day-picker";
import { ptBR } from "date-fns/locale";
import { Plaza } from "../../dto/plaza-dto";
import { Subplaza } from "../../dto/subplaza-dto";
import { DragDrop, IRef } from "../../components/forms/fileUpload";

const filterDeliverymanFormSchema = z.object({
  search: z.string(),
  company: z.union([z.string(), z.array(z.string())]).optional(),
  status: z.string().optional(),
  created_at: z.date().optional(),
  updated_at: z.date().optional(),
  plaza: z.string().optional(),
  subplaza: z.string().optional(),
  pixKeyOrAccountId: z.string().optional(),
  nextPayroll: z.string(),
});

type filterDeliverymanFormInputs = z.infer<typeof filterDeliverymanFormSchema>;

// TODO: refatorar esse componente
export function Deliverymans() {
  const [page, setPage] = useState<number>(1);
  const [company, setCompany] = useState<string>("1");
  const [deleteId, setDeleteId] = useState<number>(0);
  const [isSyncing, setIsSyncing] = useState(false);
  const [isSending, setIsSending] = useState(false);
  const { exportCsv, loading: loadingCsvExport } = useExportCsv();
  const [pixErrors, setPixErrors] = useState<Array<IUpdatePixError>>([]);
  const [loadingActivationMany, setIsLoadingActivationMany] = useState(false);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const cancelRef = React.useRef<HTMLButtonElement | null>(null);
  const pixUpdateRef = useRef<IRef>(null);

  const updatePix = useDisclosure({ id: "updade-pix" });
  const pixErrorsDrawer = useDisclosure({ id: "pix-errors-drawer" });

  const syncDeliverymen = useDeliverymansStore(
    (state) => state.syncDeliverymen
  );

  const changeDeliverymanStatus = useDeliverymansStore(
    (state) => state.patchDeliverymanStatus
  );

  const getDeliverymansByCompany = useDeliverymansStore(
    (state) => state.getAllDeliverymansByCompany
  );

  const deleteDevileryman = useDeliverymansStore(
    (state) => state.deleteDeliveryman
  );

  const me = useCurrentUserStore((state) => state.user);
  const isUserTrampay = me?.companyId === TRAMPAY_COMPANY_ID;

  const { data: companies } = useQuery({
    queryKey: ["all-companies"],
    queryFn: async () => {
      const response = await getWithAuth("/api/v1/companies/all");
      return response?.data as CompanyDto[];
    },
    initialData: [],
    enabled: isUserTrampay,
  });

  const { data: plazas } = useQuery({
    queryKey: ["all-plazas"],
    queryFn: async () => {
      const response = await getWithAuth("/api/v1/plazas");
      return response?.data as Plaza[];
    },
    initialData: [],
  });

  const { data: subplazas } = useQuery({
    queryKey: ["all-subplazas"],
    queryFn: async () => {
      const response = await getWithAuth("/api/v1/subplazas");
      return response?.data as Subplaza[];
    },
    initialData: [],
  });

  const navigate = useNavigate();
  const toast = useToast();

  const {
    register,
    handleSubmit,
    getValues,
    control,
    watch,
    formState: { errors, isSubmitting },
  } = useForm<filterDeliverymanFormInputs>({
    resolver: zodResolver(filterDeliverymanFormSchema),
    defaultValues: { nextPayroll: "false" },
  });

  const urlGenerate = (data: filterDeliverymanFormInputs) => {
    const params = [];

    if (data.search) {
      params.push(`search=${data.search}`);
    }

    if (data.company) {
      params.push(`companies=${data.company}`);
    }

    if (data.status) {
      params.push(`status=${data.status}`);
    }

    if (data.created_at) {
      params.push(`created_at=${data.created_at}`);
    }

    if (data.updated_at) {
      params.push(`updated_at=${data.updated_at}`);
    }

    if (data.plaza) {
      params.push(`plaza=${data.plaza}`);
    }

    if (data.subplaza) {
      params.push(`subplaza=${data.subplaza}`);
    }
    if (data.pixKeyOrAccountId) {
      if (data.pixKeyOrAccountId === "pixKey") {
        params.push(`pixKey=null`);
      } else if (data.pixKeyOrAccountId === "accountId") {
        params.push(`accountId=null`);
      } else if (data.pixKeyOrAccountId === "pixKeyAndAccountId") {
        params.push(`accountId=null`);
        params.push(`pixKey=null`);
      } else if (data.pixKeyOrAccountId === "pixKeyNextPayment") {
        params.push(`withoutPixKeyNextPayment=true`);
      }
    }
    if (data.nextPayroll) {
      params.push(`nextPayroll=${data.nextPayroll}`);
    }

    const timezoneOffset = new Date().getTimezoneOffset() / 60;
    let url = `page=${page}&limit=${10}&orderBy=DESC&timezoneOffset=${timezoneOffset}`;

    url += `&${params.join("&")}`;

    return url;
  };

  const fetchDeliverymansData = async () => {
    const dataForm = getValues();
    const filters = urlGenerate(dataForm);
    const prefix = isUserTrampay ? "allByTrampay" : "allByCompany";
    try {
      const data = await getDeliverymansByCompany(
        `/api/v1/deliveryMan/${prefix}?${filters}`
      );
      return data;
    } catch (error) {
      const axiosError = error as AxiosError;
      const axiosDataError = axiosError.response?.data as AxiosDataError;

      toast({
        status: "error",
        title: "Erro ao realizar operação: " + axiosDataError.message,
        duration: 2000,
      });
    }
  };

  const {
    data: deliverymansData,
    refetch,
    isLoading,
  } = useQuery(["delivery-mans-data", page], fetchDeliverymansData);

  const handleDeliverymanActivity = async (id: number, isActive: boolean) => {
    try {
      await changeDeliverymanStatus({
        id,
        status: isActive
          ? DELIVERYMAN_STATUS.ACTIVE
          : DELIVERYMAN_STATUS.INACTIVE,
      });
      refetch();
      toast({
        status: "success",
        title: "Operação realizada com sucesso!",
        duration: 2000,
      });
    } catch (error) {
      console.log(error);

      const axiosError = error as AxiosError;
      const axiosDataError = axiosError.response?.data as AxiosDataError;
      toast({
        status: "error",
        title: "Erro ao realizar operação: " + axiosDataError.message,
        duration: 2000,
      });
    }
  };

  const handleDeleteDeliveryman = async (id: number) => {
    try {
      await deleteDevileryman(id);
      refetch();
      toast({
        status: "success",
        title: `O usuário foi deletado com sucesso`,
        duration: 2000,
      });
    } catch (error) {
      console.log(error);

      const axiosError = error as AxiosError;
      const axiosDataError = axiosError.response?.data as AxiosDataError;

      toast({
        status: "error",
        title: "Erro ao realizar operação: " + axiosDataError.message,
        duration: 2000,
      });
    }
  };

  async function applySync() {
    const companyId = isUserTrampay ? +company : me?.companyId;

    try {
      if (!companyId) {
        return toast({
          status: "error",
          title:
            "Não foi possível sincronizar dados dos profissionais de entrega",
          description: "Empresa não passada",
          duration: 2000,
        });
      }

      setIsSyncing(true);

      await syncDeliverymen(companyId);
      toast({
        status: "success",
        title: "Processando a sincronização com integração!",
        duration: 2000,
      });
    } catch (err) {
      const error = err as AxiosError;
      const errorData = error.response?.data as AxiosDataError;

      toast({
        status: "error",
        title:
          "Não foi possível sincronizar os dados do profissional de entrega",
        description: errorData.message,
        duration: 4000,
      });
    } finally {
      setIsSyncing(false);
    }
  }

  async function handleActivateManyNew() {
    try {
      setIsLoadingActivationMany(true);
      await patchWithAuth("api/v1/deliveryMan/activate/status/new");
    } catch (error) {
      console.log(JSON.stringify(error));

      const axiosError = error as AxiosError;
      const axiosDataError = axiosError.response?.data as AxiosDataError;

      toast({
        status: "error",
        title: "Erro ao realizar operação: " + axiosDataError.message,
        duration: 2000,
      });
    } finally {
      setIsLoadingActivationMany(false);
    }
  }

  async function handleUpload() {
    setIsSending(true);
    setPixErrors([]);
    const randomId = crypto.randomUUID();

    try {
      const response =
        await pixUpdateRef.current?.processUpload<IUpdatePixResponse>({
          sendId: randomId,
        });

      if (response?.csv) {
        const blob = new Blob([response?.csv as BlobPart], {
          type: "text/csv;charset=utf-8;",
        });

        const url = window.URL.createObjectURL(blob);
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", "pix-nao-alterados.csv");
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        window.URL.revokeObjectURL(url);
      }

      if (response?.invalidRows) {
        if (response?.invalidRows?.length > 0) {
          setPixErrors(response?.invalidRows);
          pixErrorsDrawer.onOpen();
        }
      }

      toast({
        status: "success",
        title: "Pix atualizados!",
        description: "As chaves Pix válidas foram inseridas com sucesso!",
        duration: 5000,
      });
      updatePix.onClose();
    } catch (error) {
      if (
        error instanceof AxiosError &&
        (error.response?.status === 403 || error.response?.status === 422) &&
        error.response.data.message !== undefined
      ) {
        toast({
          status: "error",
          title: "Não foi possível enviar as planilhas",
          description: error.response.data.message,
          duration: 5000,
        });
      } else {
        toast({
          status: "error",
          title: "Não foi possível enviar as planilhas",
          description: "Não foi possível enviar a planilha. Tente novamente.",
        });
      }
      return;
    } finally {
      setIsSending(false);
      updatePix.onClose();
    }
  }

  return (
    <VStack gap={2} w="full" alignItems="flex-start">
      <Heading fontWeight="extrabold" textAlign="left">
        Entregadores
      </Heading>
      <VStack w="full" gap={4} borderRadius={8}>
        {isUserTrampay ? (
          <FormControl as="fieldset">
            <HStack alignSelf="flex-start">
              <Select
                value={company}
                onChange={(e) => setCompany(e.target.value)}
                w="30%"
                bg="whiteAlpha.800"
              >
                <option value="">Selecione uma empresa</option>
                {companies?.map((company) => (
                  <option value={company.id} key={company.id}>
                    {company.name}
                  </option>
                ))}
              </Select>
              <Button
                type="button"
                isLoading={loadingCsvExport}
                loadingText="Exportando CSV"
                disabled={loadingCsvExport}
                role="a"
                size="lg"
                color="white"
                onClick={() =>
                  exportCsv(
                    companies?.filter((cp) => String(cp.id) === company)[0]
                      .name,
                    +company,
                    urlGenerate(getValues())
                  )
                }
              >
                <Download weight="bold" size={24} />
              </Button>
              <Button
                size="lg"
                alignSelf="flex-end"
                isLoading={isSyncing}
                onClick={applySync}
              >
                Sincronizar com integração
              </Button>
              <Button
                size="lg"
                alignSelf="flex-end"
                onClick={() => navigate("/deliverymans/sync-csv")}
              >
                Adicionar novos entregadores via CSV
              </Button>
            </HStack>
          </FormControl>
        ) : null}
      </VStack>
      <VStack w="full" h="full" gap={6} borderRadius={8}>
        {!isUserTrampay ? (
          <HStack
            w={"full"}
            alignSelf="flex-start"
            alignItems={"flex-end"}
            justifyContent={"space-between"}
          >
            <VStack
              bg="whiteAlpha.800"
              border="1px"
              borderColor="gray.300"
              borderRadius={8}
              p={4}
              w="max-content"
              shadow="base"
            >
              <Stat>
                <StatLabel fontSize={["md", "xl"]}>
                  Quantidade de registros
                </StatLabel>
                <StatNumber fontSize={["xl", "3xl"]} color={"green.500"}>
                  {deliverymansData?.totalRecords}
                </StatNumber>
              </Stat>
            </VStack>
            <Flex gap={6}>
              <Button size="lg" onClick={() => updatePix.onOpen()}>
                Alteração chave Pix - Em lote
              </Button>
              <Button
                size="lg"
                alignSelf="flex-end"
                isLoading={isSyncing}
                onClick={applySync}
              >
                Sincronizar dados
              </Button>
              <Button
                role="button"
                size="lg"
                color="white"
                isLoading={loadingActivationMany}
                disabled={loadingActivationMany}
                onClick={handleActivateManyNew}
              >
                Ativar todos os novos
              </Button>
              <Button
                type="button"
                isLoading={loadingCsvExport}
                loadingText="Exportando CSV"
                disabled={loadingCsvExport}
                role="a"
                size="lg"
                color="white"
                onClick={() =>
                  exportCsv(
                    me?.company?.name as string,
                    me?.companyId as number,
                    urlGenerate(getValues())
                  )
                }
              >
                <Download weight="bold" size={24} />
              </Button>
              <Box transform="translateY(12px)">
                <Tooltip
                  hasArrow
                  placement="right"
                  label="Os dados exportados serão baseados no filtro de data dessa página"
                  fontSize="md"
                >
                  <Icon fontSize={20} color="black" />
                </Tooltip>
              </Box>
            </Flex>
          </HStack>
        ) : null}

        <Box
          w="full"
          display="flex"
          flexDir="column"
          gap={4}
          flex={1}
          borderRadius={8}
          border="1px"
          borderColor="gray.300"
          p={8}
          shadow="base"
          bg="whiteAlpha.800"
        >
          <Flex flexDir="column" gap={4}>
            <Heading size="md">Filtros</Heading>
            <Flex
              as="form"
              w="full"
              gap={4}
              alignItems="flex-start"
              onSubmit={handleSubmit(() => refetch())}
            >
              <FormControl as="fieldset" w="max-content">
                <Popover placement="right-end">
                  <PopoverTrigger>
                    <Button variant={"ghost"}>
                      {watch("created_at")
                        ? dayjs(watch("created_at")).format("DD/MM/YYYY")
                        : "Data de criação"}
                    </Button>
                  </PopoverTrigger>
                  <Portal>
                    <PopoverContent bg="white" p={2} w="max-content">
                      <PopoverHeader>
                        <Heading size="md">Data de criação</Heading>
                      </PopoverHeader>
                      <PopoverBody>
                        <Controller
                          control={control}
                          name="created_at"
                          render={({ field }) => (
                            <DayPicker
                              id="created_at"
                              mode="single"
                              selected={field.value as Date}
                              onSelect={field.onChange}
                              locale={ptBR}
                              modifiersClassNames={{
                                disabled: "btn-disabled",
                                selected: "custom-selected",
                                outside: "outside-day",
                              }}
                            />
                          )}
                        />
                      </PopoverBody>
                    </PopoverContent>
                  </Portal>
                </Popover>
              </FormControl>
              <FormControl as="fieldset" w="max-content">
                <Popover placement="right-end">
                  <PopoverTrigger>
                    <Button variant={"ghost"}>
                      {watch("updated_at")
                        ? dayjs(watch("updated_at")).format("DD/MM/YYYY")
                        : "Data de atualização"}
                    </Button>
                  </PopoverTrigger>
                  <Portal>
                    <PopoverContent bg="white" p={2} w="max-content">
                      <PopoverHeader>
                        <Heading size="md">Data de atualização</Heading>
                      </PopoverHeader>
                      <PopoverBody>
                        <Controller
                          control={control}
                          name="updated_at"
                          render={({ field }) => (
                            <DayPicker
                              id="updated_at"
                              mode="single"
                              selected={field.value as Date}
                              onSelect={field.onChange}
                              locale={ptBR}
                              modifiersClassNames={{
                                disabled: "btn-disabled",
                                selected: "custom-selected",
                                outside: "outside-day",
                              }}
                            />
                          )}
                        />
                      </PopoverBody>
                    </PopoverContent>
                  </Portal>
                </Popover>
              </FormControl>
              <FormControl
                as="fieldset"
                isInvalid={!!errors.search}
                w="fit-content"
                flexGrow={2}
                maxW={"xl"}
              >
                <Input
                  {...register("search")}
                  placeholder="Busque pelo cpf, nome ou id"
                />
                {errors.search ? (
                  <FormErrorMessage>{errors.search.message}</FormErrorMessage>
                ) : null}
              </FormControl>
              {isUserTrampay ? (
                <FormControl as="fieldset" maxW={"md"} w="fit-content">
                  <Select {...register("company")}>
                    <option value="">Selecione uma empresa</option>
                    {companies?.map((company) => (
                      <option value={company.id} key={company.id}>
                        {company.name}
                      </option>
                    ))}
                  </Select>
                </FormControl>
              ) : null}
              <FormControl as="fieldset" w="fit-content">
                <Select {...register("status")}>
                  <option value="">Todos os status</option>
                  {Object.entries(DELIVERYMAN_STATUS).map(([key, value]) => (
                    <option value={value} key={key}>
                      {deliverymanStatusTextMap[value]}
                    </option>
                  ))}
                </Select>
              </FormControl>

              {!isUserTrampay ? (
                <FormControl as="fieldset" w="fit-content">
                  <Select {...register("plaza")}>
                    <option value="">Selecione uma praça</option>
                    {plazas.map((praca) => (
                      <option value={praca.id} key={praca.id}>
                        {praca.name}
                      </option>
                    ))}
                  </Select>
                </FormControl>
              ) : null}
              {!isUserTrampay ? (
                <FormControl as="fieldset" w="fit-content">
                  <Select {...register("subplaza")}>
                    <option value="">Selecione a subpraça</option>
                    {subplazas.map((praca) => (
                      <option value={praca.id} key={praca.id}>
                        {praca.name}
                      </option>
                    ))}
                  </Select>
                </FormControl>
              ) : null}
              {!isUserTrampay ? (
                <FormControl w="fit-content">
                  <Select {...register("pixKeyOrAccountId")}>
                    <option value={undefined}> Buscar por entregador...</option>
                    <option value={"pixKey"}>Sem Pix (Todos)</option>
                    <option value={"accountId"}> Sem conta bancária</option>
                    <option value={"pixKeyAndAccountId"}>
                      Sem Pix e sem conta bancária
                    </option>
                    <option value={"pixKeyNextPayment"}>
                      Sem Pix (Próximo repasse)
                    </option>
                  </Select>
                </FormControl>
              ) : null}
              <FormControl w="fit-content">
                <FormLabel display="flex" gap="12px">
                  Próxima Folha Agendada?
                </FormLabel>
                <Controller
                  name="nextPayroll"
                  control={control}
                  render={({ field: { onChange, value } }) => (
                    <RadioGroup onChange={onChange} value={value}>
                      <Stack direction="row">
                        <Radio value="true" colorScheme="green">
                          Sim
                        </Radio>
                        <Radio value="false" colorScheme="red">
                          Não
                        </Radio>
                      </Stack>
                    </RadioGroup>
                  )}
                />
              </FormControl>
              <Button
                type="submit"
                title="search"
                width="80px"
                isLoading={isSubmitting || isLoading}
              >
                <MagnifyingGlass weight="bold" />
              </Button>
            </Flex>
          </Flex>
          <TableContainer
            w="full"
            flexDir="column"
            justifyContent="space-between"
            pb={4}
            gap={8}
            display="flex"
            style={{ containerType: "inline-size" }}
          >
            <Table variant="striped" colorScheme="green">
              <Thead>
                <Tr>
                  <Th flex={1}>ID</Th>
                  <Th textAlign="left">ID do entregador</Th>
                  <Th textAlign="left">Nome</Th>
                  <Th>CPF</Th>
                  <Th>Praça</Th>
                  <Th>Subpraça</Th>
                  <Th textAlign="center">Empresa</Th>
                  <Th isNumeric>Data de entrada</Th>
                  <Th textAlign="center">Ativo</Th>
                  <Th textAlign="center">Ações</Th>
                </Tr>
              </Thead>
              <Tbody>
                {!isLoading ? (
                  deliverymansData?.result.map((deliveryman) => (
                    <Tr
                      key={deliveryman.id}
                      cursor="pointer"
                      onClick={() =>
                        navigate(`edit/${deliveryman.id}`, {
                          relative: "route",
                        })
                      }
                    >
                      <Td
                        maxW="200px"
                        overflow={"hidden"}
                        textOverflow={"ellipsis"}
                      >
                        {deliveryman.id}
                      </Td>
                      <Td
                        maxW="200px"
                        overflow={"hidden"}
                        textOverflow={"ellipsis"}
                      >
                        {deliveryman.idEntregador}
                      </Td>
                      <Td
                        maxW="200px"
                        overflow={"hidden"}
                        textOverflow={"ellipsis"}
                      >
                        {deliveryman.nameIfood}
                      </Td>
                      <Td>{formatCPF(deliveryman.document)}</Td>
                      <Td>
                        <SimpleGrid columns={1} spacing={3}>
                          {deliveryman?.plazas?.map((plaza) => (
                            <Box key={plaza}>{plaza}</Box>
                          ))}
                        </SimpleGrid>
                      </Td>
                      <Td
                        maxW="200px"
                        overflow={"hidden"}
                        textOverflow={"ellipsis"}
                      >
                        <SimpleGrid columns={1} spacing={3}>
                          {deliveryman?.subplazas?.map((subplaza) => (
                            <Box key={subplaza}>{subplaza}</Box>
                          ))}
                        </SimpleGrid>
                      </Td>
                      <Td textAlign="center">{deliveryman.company.name}</Td>
                      <Td isNumeric>
                        {deliveryman.createdAt
                          ? dayjs(deliveryman.createdAt).format("DD/MM/YYYY")
                          : "-"}
                      </Td>
                      <Td textAlign="center">
                        {deliveryman.status === DELIVERYMAN_STATUS.ACTIVE ? (
                          <Badge variant="solid" size="md" bgColor="green">
                            Ativo
                          </Badge>
                        ) : deliveryman.status ===
                          DELIVERYMAN_STATUS.INACTIVE ? (
                          <Badge variant="solid" size="md" bgColor="red">
                            Inativo
                          </Badge>
                        ) : (
                          <Badge
                            variant="solid"
                            size="md"
                            bgColor="facebook.500"
                          >
                            Novo
                          </Badge>
                        )}
                      </Td>
                      <Td w="10%" textAlign="center">
                        <Flex gap={2} justifyContent="center">
                          <Button
                            size="sm"
                            onClick={(e) => {
                              e.stopPropagation();
                              handleDeliverymanActivity(
                                deliveryman.id,
                                !(
                                  deliveryman.status ===
                                  DELIVERYMAN_STATUS.ACTIVE
                                )
                              );
                            }}
                          >
                            {deliveryman.status === DELIVERYMAN_STATUS.ACTIVE
                              ? "Inativar"
                              : "Ativar"}
                          </Button>
                          {isUserTrampay ? (
                            <Button
                              size="sm"
                              bg="red.500"
                              _hover={{
                                bg: "red.600",
                              }}
                              onClick={(e) => {
                                e.stopPropagation();
                                setDeleteId(deliveryman.id);
                                onOpen();
                              }}
                            >
                              Deletar
                            </Button>
                          ) : null}
                          {isUserTrampay ? (
                            <Button
                              size="sm"
                              bg="orange.500"
                              zIndex={100}
                              _hover={{
                                bg: "orange.600",
                              }}
                              onClick={(e) => {
                                e.stopPropagation();
                                navigate(
                                  `/debts-support/deliveryman/create/${deliveryman.id}`
                                );
                              }}
                            >
                              Criar débito
                            </Button>
                          ) : null}
                        </Flex>
                      </Td>
                    </Tr>
                  ))
                ) : (
                  <LoadingLogo />
                )}
              </Tbody>
            </Table>
            <AlertDialog
              isOpen={isOpen}
              leastDestructiveRef={cancelRef}
              onClose={onClose}
            >
              <AlertDialogOverlay>
                <AlertDialogContent>
                  <AlertDialogHeader fontSize="lg" fontWeight="bold">
                    Deletar entregador
                  </AlertDialogHeader>

                  <AlertDialogBody>
                    Você tem certeza que deseja deletar este entregador?
                  </AlertDialogBody>

                  <AlertDialogFooter>
                    <Button onClick={onClose}>Cancelar</Button>
                    <Button
                      colorScheme="red"
                      onClick={async () => {
                        await handleDeleteDeliveryman(deleteId);
                        onClose();
                      }}
                      ml={3}
                    >
                      Deletar entregador
                    </Button>
                  </AlertDialogFooter>
                </AlertDialogContent>
              </AlertDialogOverlay>
            </AlertDialog>
          </TableContainer>
          <Pagination
            page={page}
            setPage={setPage}
            totalPages={deliverymansData?.totalPages || 1}
          />
        </Box>

        <Modal
          isOpen={updatePix.isOpen}
          onClose={updatePix.onClose}
          size="5xl"
          id="update-pix"
        >
          <ModalOverlay />
          <ModalContent mx={4}>
            <ModalHeader>Upload de Arquivos</ModalHeader>
            <ModalCloseButton />
            <ModalBody px={16}>
              <Flex gap={16} flexDir="column">
                <Flex flexDir="column" gap={4}>
                  <Heading>Alterar Pix</Heading>
                  <DragDrop
                    uploadUrl="/api/v1/deliveryMan/pix/by/csv"
                    maxFiles={1}
                    ref={pixUpdateRef}
                    isPatch={true}
                  />
                </Flex>
              </Flex>
            </ModalBody>

            <ModalFooter my={4}>
              <Button variant="ghost" mr={3} onClick={updatePix.onClose}>
                Fechar
              </Button>
              <Button
                color="white"
                onClick={handleUpload}
                isLoading={isSending}
                isDisabled={isSending}
                loadingText="Atualizando chaves pix"
              >
                Enviar .CSV
              </Button>
            </ModalFooter>
          </ModalContent>
        </Modal>

        <Drawer
          onClose={pixErrorsDrawer.onClose}
          isOpen={pixErrorsDrawer.isOpen}
          size="sm"
          id="pix-errors-drawer"
        >
          <DrawerOverlay />
          <DrawerContent>
            <DrawerCloseButton />
            <DrawerHeader>Erro na atualização de chave pix </DrawerHeader>
            <DrawerBody>
              <List spacing={3}>
                {pixErrors?.map((error) => (
                  <ListItem key={error.document}>
                    <ListIcon as={WarningCircle} color="red.500" />
                    {`A chave Pix ${error.pixKey} do entregador de CPF ${error.document} não foi inserida. ${error.error};`}
                  </ListItem>
                ))}
              </List>
            </DrawerBody>
          </DrawerContent>
        </Drawer>
      </VStack>
    </VStack>
  );
}
