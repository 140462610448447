import { IPaginationOptions } from "../../dto/generics/pagination-options.dto";
import {
  getWithAuth,
  deleteWithAuth,
  postWithAuth,
  patchWithAuth,
} from "../basicService";

type BaseCrud<T> = {
  create: (data: object) => Promise<T | null>;
  show: (id: number) => Promise<T | null>;
  filter: (filter: object) => Promise<T[] | null>;
  filterWithPagination: (
    filter: object,
    paginationOptions: IPaginationOptions
  ) => Promise<{
    data: T[];
    totalRecords: number;
    nextPage: boolean;
    totalPages: number;
    page: number;
    limit: number;
  }>;
  patch: (params: {
    id: number;
    attr: string;
    value: string;
  }) => Promise<T | null>;
  update: (params: { id: number; data: object }) => Promise<T | null>;
  destroy: (params: { id: number }) => Promise<T | null>;
};

type AdditionalMethods<T> = {
  [key: string]: (...args: unknown[]) => Promise<T | T[] | null | void>;
};

export type ExtendedBaseCrud<T> = BaseCrud<T> & AdditionalMethods<T>;

export type RequestFunction<T> = (
  ...args: unknown[]
) => Promise<T | T[] | null>;

export type Service<T> = {
  [key: string]: RequestFunction<T>;
};

export type ParametersActions<T> = Parameters<Service<T>[keyof Service<T>]>;

export const useBaseCrud = <T>(baseURL: string): BaseCrud<T> => {
  const create = async <T>(data: object): Promise<T | null> => {
    const response = await postWithAuth(`${baseURL}`, data);
    return response?.data.data || null;
  };

  const show = async <T>(id: number): Promise<T | null> => {
    const response = await getWithAuth(`${baseURL}/${id}`);
    return response?.data || null;
  };

  const filter = async <T>(filter: object): Promise<T[] | null> => {
    const params = new URLSearchParams();
    Object.entries(filter).forEach(([key, value]) => params.set(key, value));
    const response = await getWithAuth(`${baseURL}`, {}, { params });
    return response?.data || null;
  };

  const filterWithPagination = async <T>(
    filter: object,
    paginationOptions: IPaginationOptions
  ): Promise<{
    data: T[];
    totalRecords: number;
    nextPage: boolean;
    totalPages: number;
    page: number;
    limit: number;
  }> => {
    const params = new URLSearchParams();
    Object.entries({ ...filter, ...paginationOptions }).forEach(
      ([key, value]) => {
        if (Array.isArray(value)) {
          if (value.length > 0) {
            value.forEach((item) => params.append(`${key}[]`, String(item)));
          }
        } else {
          params.set(key, String(value));
        }
      }
    );
    const response = await getWithAuth(`${baseURL}`, {}, { params });
    return response?.data;
  };

  const patch = async (params: { id: number; attr: string; value: string }) => {
    const response = await patchWithAuth(`${baseURL}/${params.id}`, {
      [`${params.attr}`]: params.value,
    });

    return response?.data || null;
  };

  const update = async <T>(params: {
    id: number;
    data: object;
  }): Promise<T | null> => {
    const response = await patchWithAuth(
      `${baseURL}/${params.id}`,
      params.data
    );
    return response?.data.data || null;
  };

  const destroy = async <T>(params: { id: number }): Promise<T | null> => {
    const response = await deleteWithAuth(`${baseURL}/${params.id}`);
    return response?.data.data || null;
  };

  return {
    create,
    show,
    filter,
    filterWithPagination,
    patch,
    update,
    destroy,
  };
};
