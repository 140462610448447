import {
  Badge,
  Box,
  Flex,
  Grid,
  HStack,
  Heading,
  VStack,
} from "@chakra-ui/layout";
import {
  Button,
  FormControl,
  FormLabel,
  Input,
  Popover,
  PopoverBody,
  PopoverContent,
  PopoverTrigger,
  Portal,
  Table,
  TableContainer,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
  useColorMode,
  Select,
} from "@chakra-ui/react";
import dayjs from "dayjs";
import { Pagination } from "../../../components/pagination";
import { convertCentsToReais } from "../../../utils/convert-money";
import { formatCPF } from "../../../utils/format-document";
import { useLoaderData, useSearchParams } from "react-router-dom";
import { useForm, Controller } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { z } from "zod";
import { ptBR } from "date-fns/locale";
import { DateRange, DayPicker } from "react-day-picker";
import { parseSearchParamsToObject } from "../../../utils/parse-search-params";
import {
  TransactionDto,
  transactionColorMap,
  transactionTextMap,
} from "../../../dto/transaction-dto";
import { TransactionStatus } from "../../../enums/transaction-status";
import { getWithAuth } from "../../../services/basicService";
import { useQuery } from "@tanstack/react-query";
import {
  TransactionTypes,
  transactionTypeTextMap,
} from "../../../enums/transaction-types.enum";
import { TransactionDownloadButton } from "../../payments/components/TransactionDownloadButton";

type dataResponse = {
  transactions: TransactionDto[];
  hasNextPage: boolean;
  page: number;
  totalPages: number;
  totalEntries: number;
};

const filtersFormSchema = z.object({
  document: z.string().optional(),
  amountRange: z.array(z.number().min(0)).optional(),
  name: z.string().optional(),
  dateRange: z
    .object({
      to: z.date().optional(),
      from: z.date().optional(),
    })
    .optional(),
  status: z.string().optional(),
  companyId: z.string().optional(),
  accountId: z.string().optional(),
  type: z.string().optional(),
});

type filtersFormInput = z.infer<typeof filtersFormSchema>;

const searchParamsSchema = z.object({
  document: z.string().optional(),
  amountStart: z.coerce.number().min(0).optional(),
  amountEnd: z.coerce.number().optional(),
  name: z.string().optional(),
  startDate: z.string().optional(),
  endDate: z.string().optional(),
  page: z.coerce.number().optional(),
  status: z.string().optional(),
  companyId: z.string().optional(),
  type: z.string().optional(),
  accountId: z.string().optional(),
});

export function ListAllTransactionsSupport() {
  const data = useLoaderData() as dataResponse;
  const { colorMode } = useColorMode();

  const [searchParams, setSearchParams] = useSearchParams({ page: "1" });

  const parsedSearchParams = parseSearchParamsToObject({
    schema: searchParamsSchema,
    URLSearchParams: searchParams,
  });

  const { data: companies } = useQuery<
    {
      name: string;
      id: number;
      account_id: number;
      paymentAccountId: number;
    }[]
  >({
    queryKey: ["all-companies"],
    queryFn: async () => {
      const response = await getWithAuth("/api/v1/companies/all");
      return response?.data;
    },
  });

  const selectedCompany = companies?.find(
    (company) => company.id === Number(parsedSearchParams.companyId)
  );

  const {
    register,
    handleSubmit,
    control,
    formState: { isDirty },
  } = useForm<filtersFormInput>({
    resolver: zodResolver(filtersFormSchema),
    defaultValues: {
      document: parsedSearchParams?.document || undefined,
      amountRange: [
        parsedSearchParams?.amountStart
          ? parsedSearchParams?.amountStart / 100
          : 0,
        parsedSearchParams?.amountEnd ? parsedSearchParams?.amountEnd / 100 : 0,
      ],
      name: parsedSearchParams?.name || undefined,
      dateRange:
        {
          from: parsedSearchParams.startDate
            ? dayjs(parsedSearchParams?.startDate).toDate()
            : undefined,
          to: parsedSearchParams.endDate
            ? dayjs(parsedSearchParams?.endDate).toDate()
            : undefined,
        } || undefined,
      status: parsedSearchParams.status || undefined,
      companyId: parsedSearchParams.companyId || undefined,
      accountId: parsedSearchParams.accountId || undefined,
      type: parsedSearchParams.type || undefined,
    } || {
      document: undefined,
      amountRange: [0, 5000],
      dateRange: undefined,
      name: undefined,
      status: undefined,
      companyId: undefined,
      type: undefined,
    },
  });

  function onSubmit(data: filtersFormInput) {
    const newUrlSearchParams = new URLSearchParams();
    Object.entries(data).forEach(([key, value]) => {
      if (key === "dateRange") {
        const dateRange = value as DateRange;

        if (dateRange.from) {
          newUrlSearchParams.set(
            "startDate",
            dayjs(dateRange?.from).toISOString()
          );
        }

        if (!dateRange.from) {
          newUrlSearchParams.delete("startDate");
        }

        if (dateRange.to) {
          newUrlSearchParams.set("endDate", dayjs(dateRange?.to).toISOString());
        }

        if (!dateRange.to) {
          newUrlSearchParams.delete("endDate");
        }
        return;
      }

      if (key === "amountRange") {
        const [amountStart, amountEnd] = value as number[];

        if (amountEnd !== 0) {
          newUrlSearchParams.set("amountStart", (amountStart * 100).toString());
          newUrlSearchParams.set("amountEnd", (amountEnd * 100).toString());
        }
        return;
      }

      if (value !== "" && value !== undefined && key !== "amountRange") {
        newUrlSearchParams.set(key, value.toString());
      }
    });

    setSearchParams(newUrlSearchParams);
  }

  const handlePageChange = (page: number) => {
    setSearchParams((prev) => {
      prev.set("page", page.toString());
      return prev;
    });
  };

  return (
    <VStack gap={8} w="full">
      <Flex w="full" justifyContent="space-between" alignItems="center">
        <Heading fontWeight="extrabold" textAlign="left">
          Extrato de transações
        </Heading>
      </Flex>

      <Box
        w="full"
        display="flex"
        flexDir="column"
        gap={4}
        flex={1}
        borderRadius={8}
        border="1px"
        borderColor="gray.300"
        p={8}
        shadow="base"
        bg="whiteAlpha.800"
      >
        <Flex
          as="form"
          flexDir="column"
          gap={4}
          id="form"
          w="full"
          p={4}
          onSubmit={handleSubmit(onSubmit)}
        >
          <Heading fontWeight="bold" textAlign="left" as="h2" fontSize="2xl">
            Filtros
          </Heading>
          <Grid
            templateColumns={["1fr", "1fr 1fr"]}
            w="full"
            justifyContent="center"
            gap={8}
          >
            <FormControl as="fieldset">
              <FormLabel as="legend">Nome</FormLabel>
              <Input
                type="text"
                {...register("name")}
                bg={colorMode === "dark" ? "gray.800" : "gray.100"}
                fontWeight="bold"
                color={colorMode === "dark" ? "gray.300" : "gray.700"}
                placeholder="Busque pelo nome..."
                _placeholder={{
                  color: "gray.500",
                }}
              />
            </FormControl>
            <FormControl as="fieldset">
              <FormLabel as="legend">CPF/CNPJ</FormLabel>
              <Input
                type="text"
                {...register("document")}
                bg={colorMode === "dark" ? "gray.800" : "gray.100"}
                fontWeight="bold"
                color={colorMode === "dark" ? "gray.300" : "gray.700"}
                placeholder="Busque pelo CPF ou CNPJ..."
                _placeholder={{
                  color: "gray.500",
                }}
              />
            </FormControl>
            <FormControl as="fieldset">
              <FormLabel as="legend">Status</FormLabel>
              <Select
                variant="filled"
                size="md"
                {...register("status")}
                w="full"
              >
                <option value="">Todos</option>
                {Object.entries(TransactionStatus)
                  .filter(
                    ([, value]) =>
                      value !== TransactionStatus.APPROVED &&
                      value !== TransactionStatus.ERROR
                  )
                  .map(([key, value]) => (
                    <option value={value} key={key}>
                      {transactionTextMap[value]}
                    </option>
                  ))}
              </Select>
            </FormControl>
            {/* <FormControl as="fieldset" w="100%">
              <FormLabel as="legend" id="slider-intervalo-valores">
                Intervalo de valor (R$)
              </FormLabel>
              <Controller
                control={control}
                name="amountRange"
                render={({ field }) => (
                  <RangeSlider
                    // eslint-disable-next-line jsx-a11y/aria-proptypes
                    aria-labelledby={["slider-intervalo-valores"]}
                    // eslint-disable-next-line jsx-a11y/aria-proptypes
                    aria-label={["0", "5000"]}
                    defaultValue={[0, 5000]}
                    min={0}
                    max={5000}
                    value={field.value}
                    onChange={field.onChange}
                    w="100%"
                    step={100}
                  >
                    <RangeSliderTrack>
                      <RangeSliderFilledTrack bg="green.500" />
                    </RangeSliderTrack>
                    <RangeSliderThumb
                      index={0}
                      position="relative"
                      bg="green.700"
                    >
                      <Box
                        position="absolute"
                        bottom={0}
                        left={0}
                        transform="auto"
                        translateY={"120%"}
                        translateX={"-30%"}
                        textAlign={"center"}
                        color={"white"}
                        paddingX={2}
                        borderRadius={4}
                      >
                        {field.value ? field.value[0] : null}
                      </Box>
                    </RangeSliderThumb>
                    <RangeSliderThumb
                      index={1}
                      position="relative"
                      bg="green.700"
                    >
                      <Box
                        position="absolute"
                        bottom={0}
                        left={0}
                        transform="auto"
                        translateY={"120%"}
                        translateX={"-30%"}
                        textAlign={"center"}
                        bg="green.500"
                        color={"white"}
                        paddingX={2}
                        borderRadius={4}
                      >
                        {field.value ? field.value[1] : null}
                      </Box>
                    </RangeSliderThumb>
                  </RangeSlider>
                )}
              />
            </FormControl> */}
            <FormControl as="fieldset">
              <FormLabel as="legend">Empresa</FormLabel>
              <Select
                variant="filled"
                {...register("companyId")}
                size="md"
                w="full"
              >
                <option value="">Todas</option>
                {companies?.map((company) => (
                  <option value={company.id} key={company.id}>
                    {company.name}
                  </option>
                ))}
              </Select>
            </FormControl>
            <FormControl as="fieldset">
              <FormLabel as="legend">Tipo da transação</FormLabel>
              <Select variant="filled" size="md" {...register("type")} w="full">
                <option value="">Todos</option>
                {Object.entries(TransactionTypes).map(([key, value]) => (
                  <option value={value} key={key}>
                    {transactionTypeTextMap[value]}
                  </option>
                ))}
              </Select>
            </FormControl>
            {parsedSearchParams.companyId &&
              selectedCompany?.account_id &&
              selectedCompany?.paymentAccountId && (
                <FormControl as="fieldset">
                  <FormLabel as="legend">Tipo da conta</FormLabel>
                  <Select
                    variant="filled"
                    size="md"
                    {...register("accountId")}
                    focusBorderColor="green.500"
                    w="full"
                  >
                    <option value="">Todas as Contas</option>
                    {selectedCompany?.account_id && (
                      <option value={selectedCompany.account_id}>
                        {`Principal: ${selectedCompany.name} - ${selectedCompany.account_id}`}
                      </option>
                    )}
                    {selectedCompany?.paymentAccountId && (
                      <option value={selectedCompany.paymentAccountId}>
                        {`Repasse: ${selectedCompany.name} - ${selectedCompany.paymentAccountId}`}
                      </option>
                    )}
                  </Select>
                </FormControl>
              )}
            <FormControl as="fieldset" w="max-content">
              <FormLabel as="legend">Data de transferência</FormLabel>
              <Popover placement="right-end">
                <PopoverTrigger>
                  <Button>Escolha a sua data</Button>
                </PopoverTrigger>
                <Portal>
                  <PopoverContent
                    bg={colorMode === "dark" ? "gray.900" : "white"}
                    p={2}
                    w="max-content"
                  >
                    <PopoverBody>
                      <Controller
                        control={control}
                        name="dateRange"
                        render={({ field }) => (
                          <DayPicker
                            id="dateFilter"
                            mode="range"
                            selected={field.value as DateRange}
                            onSelect={field.onChange}
                            locale={ptBR}
                            modifiersClassNames={{
                              disabled: "btn-disabled",
                              selected: "custom-selected",
                              outside: "outside-day",
                            }}
                          />
                        )}
                      />
                    </PopoverBody>
                  </PopoverContent>
                </Portal>
              </Popover>
            </FormControl>
          </Grid>
          <Button
            type="submit"
            form="form"
            bg="green.600"
            w="max-content"
            color="whiteAlpha.900"
            _hover={{
              bg: "green.700",
            }}
            px={8}
            size="md"
            alignSelf="flex-end"
            isDisabled={!isDirty}
          >
            Filtrar
          </Button>
        </Flex>
        <TableContainer
          w="full"
          pb={4}
          gap={4}
          display="flex"
          flexDir="column"
          style={{ containerType: "inline-size" }}
        >
          <Table>
            <Thead>
              <Tr>
                <Th flex={1}>Nome</Th>
                <Th textAlign="center">CPF/CNPJ</Th>
                <Th textAlign="center">Empresa</Th>
                <Th textAlign="center">Tipo de Transferência</Th>
                <Th textAlign="center">Tipo de conta</Th>
                <Th textAlign="center">Pix Key</Th>
                <Th isNumeric>Valor</Th>
                <Th textAlign="center" cursor="pointer">
                  Data de transferência
                </Th>
                <Th textAlign="center">Status</Th>
                <Th textAlign="center">Comprovante</Th>
              </Tr>
            </Thead>
            <Tbody>
              {data?.transactions?.map((transaction) => {
                return (
                  <Tr fontWeight={600} key={transaction.id}>
                    <Td
                      maxW="200px"
                      overflow={"hidden"}
                      textOverflow={"ellipsis"}
                      textTransform={"capitalize"}
                    >
                      {transaction.name}
                    </Td>
                    <Td textAlign="center">
                      {formatCPF(transaction.document)}
                    </Td>
                    <Td textAlign="center">
                      {transaction.company?.name || "Entregador Nuvem"}
                    </Td>
                    <Td textAlign="center">
                      {
                        transactionTypeTextMap[
                          transaction.type as TransactionTypes
                        ]
                      }
                    </Td>
                    <Td textAlign="center">
                      {transaction.account_id_from ===
                        transaction.company.account_id ||
                        (transaction.account_id_to ===
                          transaction.company.account_id &&
                          "Conta Principal")}
                      {transaction.account_id_to ===
                        transaction.company.paymentAccountId ||
                        (transaction.account_id_from ===
                          transaction.company.paymentAccountId &&
                          "Conta Repasse")}
                    </Td>
                    <Td textAlign="center">{transaction.pixKey}</Td>
                    {transaction.account_id_to ===
                      transaction.company.account_id ||
                      (transaction.account_id_to ===
                      transaction.company.paymentAccountId ? (
                        <Td
                          isNumeric
                          sx={{
                            color: "green.600",
                          }}
                        >
                          + {convertCentsToReais(transaction.amount)}
                        </Td>
                      ) : (
                        <Td
                          isNumeric
                          sx={{
                            color:
                              transaction.status === "reversal"
                                ? "green.600"
                                : "red.600",
                          }}
                        >
                          {transaction.status === "reversal" ? "+" : "-"}{" "}
                          {convertCentsToReais(transaction.amount)}
                        </Td>
                      ))}
                    <Td textAlign="center">
                      {dayjs(
                        transaction.transactionDate ?? transaction.createdAt
                      ).format("DD/MM/YYYY HH:mm")}
                    </Td>
                    <Td textAlign="center">
                      <Badge
                        variant="solid"
                        px={4}
                        backgroundColor={
                          transactionColorMap[
                            transaction.status as TransactionStatus
                          ]
                        }
                      >
                        {
                          transactionTextMap[
                            transaction.status as TransactionStatus
                          ]
                        }
                      </Badge>
                    </Td>
                    <Td textAlign="center">
                      <TransactionDownloadButton
                        transactionId={transaction.id}
                      />
                    </Td>
                  </Tr>
                );
              })}
            </Tbody>
          </Table>
        </TableContainer>
        <HStack gap={8} pt={8} justifyContent="flex-end">
          <Pagination
            page={parsedSearchParams.page || 1}
            setPage={
              handlePageChange as React.Dispatch<React.SetStateAction<number>>
            }
            totalPages={data?.totalPages ? data.totalPages : 1}
          />
        </HStack>
      </Box>
    </VStack>
  );
}
