import { useQueries } from "@tanstack/react-query";
import { DockIndividualDocumentsList } from "../../../../dto/docker-individuals-dto";
import { getWithAuth } from "../../../../services/basicService";
import {
  Badge,
  Box,
  Flex,
  Heading,
  ListItem,
  SimpleGrid,
  StackDivider,
  Text,
  UnorderedList,
  VStack,
} from "@chakra-ui/layout";
import { Button, Card, CardBody, SkeletonText } from "@chakra-ui/react";
import { Download } from "@phosphor-icons/react";
import { DeliverymanDto } from "../../../../dto/deliveryman-dto";
import { IndividualDisplayOnly } from "../../dock-users/details/display-only";
import { Chip } from "../../../../components/chip/chip";
import { DeliverymanTransactions } from "./transactions";
import dayjs from "dayjs";
import { DeliverymanBalances } from "./balances";
import { DeliverymanAdvances } from "./advances";
import {
  BlockedIdResponse,
  BlockedProposalUserDto,
} from "../../../../dto/deliveryman-blocked-dto";
import { fortnightValidForToday } from "../../../../utils/fortnight-valid-for-today";
import { CountBalances } from "../../../../dto/user-balance-dto";

export function DeliverymanDetails({
  deliveryman,
}: {
  deliveryman: DeliverymanDto;
}) {
  const { from } = fortnightValidForToday();
  const [
    { data: userInfo, isLoading: isLoadingInfo },
    { data: userDocuments, isLoading: isLoadingDocuments },
    { data: BlockedProposal },
    { data: FortnightBalance },
    { data: BlockedByIdEntregador },
  ] = useQueries({
    queries: [
      {
        queryKey: ["app-user-details", deliveryman.document],
        queryFn: async () => {
          const response = await getWithAuth(
            `/api/v1/userExternal/app-users/company/${deliveryman.document}`
          );

          return response?.data.individual as IndividualDisplayOnly;
        },
        keepPreviousData: true,
        refetchOnWindowFocus: false,
        retryOnMount: false,
      },
      {
        queryKey: ["app-user-documents", deliveryman.document],
        queryFn: async () => {
          const response = await getWithAuth(
            `/api/v1/userExternal/get-documents/${deliveryman.document}`
          );

          return response?.data as DockIndividualDocumentsList;
        },
        keepPreviousData: true,
        refetchOnWindowFocus: false,
        retryOnMount: false,
      },
      {
        queryKey: ["blocked-proposal-user", deliveryman.document],
        queryFn: async () => {
          const response = await getWithAuth(
            `/api/v1/proposalUser/details?cpf=${deliveryman.document}`
          );

          return response?.data as BlockedProposalUserDto[];
        },
        keepPreviousData: true,
        refetchOnWindowFocus: false,
        retryOnMount: false,
      },
      {
        queryKey: ["fortnight-balance", deliveryman.companyId],
        queryFn: async () => {
          const response = await getWithAuth(
            `/api/v1/userAdvanceBalance/countBalancesByDate?companyId=${
              deliveryman.companyId
            }&startDate=${dayjs(from).utc().format("YYYY-MM-DD")}`
          );

          return response?.data as CountBalances[];
        },
        keepPreviousData: true,
        refetchOnWindowFocus: false,
        retryOnMount: false,
      },
      {
        queryKey: ["blocked-by-id-entregador", deliveryman.idEntregador],
        queryFn: async () => {
          const response = await getWithAuth(
            `/api/v1/deliveryMan/blockedByIdEntregador/${deliveryman.idEntregador}`
          );

          return response?.data as BlockedIdResponse;
        },
        keepPreviousData: true,
        refetchOnWindowFocus: false,
        retryOnMount: false,
      },
    ],
  });

  return (
    <VStack
      w="full"
      borderRadius={8}
      border="1px"
      borderColor="gray.300"
      p={8}
      spacing={8}
      shadow="base"
      bg="whiteAlpha.800"
      divider={<StackDivider borderColor="gray.200" />}
      align="stretch"
    >
      <VStack align="stretch" w="full" gap={4}>
        <Flex gap={4}>
          <Heading size="md" fontWeight="extrabold" textAlign="left">
            Dados gerais
          </Heading>
        </Flex>

        <SimpleGrid columns={2} spacingX="40px" spacingY="20px">
          <Box borderRadius={8} shadow="base" bg="gray.100" height="auto">
            <UnorderedList padding={5}>
              <ListItem>
                Status na Trampay:{" "}
                <Chip key={1} value={deliveryman?.status || ""} />
              </ListItem>
              <ListItem>
                Bloqueado pelo CPF:{" "}
                <Chip
                  key={
                    BlockedProposal && Number(BlockedProposal.length) > 0
                      ? BlockedProposal[0].id
                      : "1"
                  }
                  value={
                    BlockedProposal && Number(BlockedProposal.length) > 0
                      ? BlockedProposal[0].isblocked
                        ? "Sim"
                        : "Não"
                      : "Não"
                  }
                />
              </ListItem>
              <ListItem>
                Bloqueado pelo ID:{" "}
                <Chip
                  key={BlockedByIdEntregador?.id}
                  value={BlockedByIdEntregador?.isBlocked ? "Sim" : "Não"}
                />
              </ListItem>
              <ListItem>
                Data do cadastro:{" "}
                <Chip
                  key={1}
                  value={dayjs(deliveryman?.createdAt || "").format(
                    "DD-MM-YYYY"
                  )}
                />
              </ListItem>
              <ListItem>
                Data do ativação:{" "}
                <Chip
                  key={1}
                  value={dayjs(deliveryman?.lastActiveDate || "").format(
                    "DD-MM-YYYY"
                  )}
                />
              </ListItem>
            </UnorderedList>
          </Box>
          <Box borderRadius={8} shadow="base" bg="gray.100" height="auto">
            <UnorderedList padding={5}>
              <ListItem>
                Empresa:{" "}
                <Chip key={1} value={deliveryman?.company.name || ""} />
              </ListItem>
              <ListItem>
                Status da empresa:{" "}
                <Chip
                  key={1}
                  value={deliveryman?.company?.status?.name || ""}
                />
              </ListItem>
              <ListItem>
                Integração:{" "}
                <Chip key={1} value={deliveryman?.company?.integration || ""} />
              </ListItem>
              <ListItem>
                Dias que a empresa subiu saldos:{" "}
                <Chip
                  key={FortnightBalance?.length}
                  value={
                    FortnightBalance?.map((fb) =>
                      dayjs(fb.reference_date).utc().format("DD/MM")
                    ).join(" | ") || "Não subiu desta quinzena"
                  }
                />
              </ListItem>
            </UnorderedList>
          </Box>
          <Box borderRadius={8} shadow="base" bg="gray.100" height="auto">
            <UnorderedList padding={5}>
              <ListItem>
                Status Dock:{" "}
                {isLoadingInfo ? (
                  "Buscando na Dock...."
                ) : (
                  <Chip key={1} value={userInfo?.status || "-"} />
                )}
              </ListItem>
              <ListItem>
                Código SPD:{" "}
                {isLoadingInfo ? (
                  "Buscando na Dock...."
                ) : (
                  <Chip
                    key={1}
                    value={
                      userInfo?.statusSPD?.map((spd) => spd.name).join(" | ") ||
                      "-"
                    }
                  />
                )}
              </ListItem>
            </UnorderedList>
          </Box>
          <Box borderRadius={8} shadow="base" bg="gray.100" height="auto">
            <UnorderedList padding={5}>
              <ListItem>
                Praça:{" "}
                <Chip
                  key={1}
                  value={
                    Number(deliveryman?.deliverymanPlazas.length) === 0
                      ? "Sem praça vinculada"
                      : deliveryman?.deliverymanPlazas
                          .map((p) => p.plaza.name)
                          .join(",") || "chamar tecnologia - plaza"
                  }
                />
              </ListItem>
              <ListItem>
                Sub-Praças:{" "}
                <Chip
                  key={1}
                  value={
                    Number(deliveryman?.deliverymanSubplazas.length) === 0
                      ? "Todas da praça vinculada"
                      : deliveryman?.deliverymanSubplazas
                          .map((sp) => sp.subplaza.name)
                          .join(" / ") || "chamar tecnologia - subplaza"
                  }
                />
              </ListItem>
              <ListItem>
                Dias da semana:{" "}
                <Chip
                  key={1}
                  value={
                    Number(
                      (deliveryman?.allowedWorkdays?.weekdays || []).length
                    ) === 0
                      ? "Não restringido"
                      : (deliveryman?.allowedWorkdays?.weekdays || [])
                          .map((wd) => wd.value)
                          .join(" / ") || "chamar tecnologia - weekdays"
                  }
                />
              </ListItem>
            </UnorderedList>
          </Box>
        </SimpleGrid>
      </VStack>
      <VStack align="stretch" gap={2} w="full">
        <Heading size="md" fontWeight="extrabold" textAlign="left">
          Documentos na dock
        </Heading>
        <Box
          borderRadius={8}
          padding={8}
          shadow="base"
          bg="gray.100"
          height="auto"
        >
          <Flex align="stretch" flexDir="row" wrap="wrap" gap={4}>
            {!isLoadingDocuments ? (
              userDocuments?.result.map((document) => (
                <Card maxW="sm" key={document.documentId} minW="200px">
                  <CardBody>
                    <Flex gap={4} justifyContent="space-between">
                      <VStack>
                        <Text>{document.category}</Text>
                        {document.invalidDetails ? (
                          <Badge colorScheme="red">
                            {`Inválido, código: ${document.invalidDetails.reasonCode}`}
                          </Badge>
                        ) : null}
                      </VStack>
                      <Button
                        onClick={async () => {
                          const response = await getWithAuth(
                            `/api/v1/userExternal/get-documents/download/${document.documentId}`
                          );

                          window.open(response?.data, "_blank");
                        }}
                        size="sm"
                        color="whiteAlpha.900"
                      >
                        <Download size={20} weight="bold" />
                      </Button>
                    </Flex>
                  </CardBody>
                </Card>
              ))
            ) : (
              <SkeletonText w="full" noOfLines={8} skeletonHeight={6} />
            )}
          </Flex>
        </Box>
      </VStack>
      <Flex gap={4}>
        <Heading size="md" fontWeight="extrabold" textAlign="left">
          Últimos saldos
        </Heading>
      </Flex>
      <DeliverymanBalances deliveryman={deliveryman} />
      <Flex>
        <Heading size="md" fontWeight="extrabold" textAlign="left">
          Adiantamentos
        </Heading>
      </Flex>
      <DeliverymanAdvances deliveryman={deliveryman} />
      <Flex gap={4}>
        <Heading size="md" fontWeight="extrabold" textAlign="left">
          Últimas transferências
        </Heading>
      </Flex>
      <DeliverymanTransactions deliveryman={deliveryman} />
    </VStack>
  );
}
