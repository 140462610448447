import { Box, Flex, HStack, Heading, VStack } from "@chakra-ui/layout";
import { Button, useToast } from "@chakra-ui/react";
import { Link } from "react-router-dom";
import { GenericList } from "../../../../components/scales/generic-list";
import { SubplazaFormCreate } from "./create-one";
import { useState } from "react";
import { CaretLeft } from "@phosphor-icons/react";
import { useSubplazaCachedService } from "../../../../services/api/subplazas";
import {
  ISubplazaSettingDto,
  Subplaza,
  textSchemeMap,
} from "../../../../dto/subplaza-dto";
import { SubplazaFormEdit } from "./edit";
import { format, formatDate } from "../../../../utils/format-date";
import { acceptanceEnum } from "../../../../enums/acceptance.enum";
import { usePlazaCachedService } from "../../../../services/api/plaza";

export function Subplazas() {
  const [action, setAction] = useState<string>("show");
  const [selectedSubplaza, setSelectedSubplaza] = useState<Subplaza>();

  /** LOAD PLAZA */
  const { useQueryAction: usePlazaQueryAction } = usePlazaCachedService();
  const plazaQuery = usePlazaQueryAction("filter", {});
  const plazas = plazaQuery.data;

  const formatCreatedAt = (row: Record<string, unknown>) =>
    formatDate(row, "createdAt", format.BR, "/");
  const formatUpdatedAt = (row: Record<string, unknown>) =>
    formatDate(row, "updatedAt", format.BR, "/");

  const getAcceptanceType = (row: Record<string, unknown>) => {
    const setting = row?.setting as ISubplazaSettingDto;
    return textSchemeMap[setting?.acceptance || acceptanceEnum.MANUALLY];
  };

  const getPlazaName = (row: Record<string, unknown>) => {
    const plazaName = plazas?.find((plaza) => plaza.id === row?.plazaId)
      ?.name as string;
    return plazaName;
  };

  const columns = [
    { header: "ID", accessor: "id" },
    { header: "Nome", accessor: "name" },
    {
      header: "Aceite",
      accessor: "setting.acceptance",
      dataTransform: getAcceptanceType,
    },
    {
      header: "Praça",
      accessor: "plazaName",
      dataTransform: getPlazaName,
    },
    {
      header: "Data de criação",
      accessor: "createdAt",
      dataTransform: formatCreatedAt,
    },
    {
      header: "Última atualização",
      accessor: "updatedAt",
      dataTransform: formatUpdatedAt,
    },
  ];

  const {
    useQueryAction: useSubplazaQueryAction,
    useMutationAction: useSubplazaMutationAction,
  } = useSubplazaCachedService();
  const filterSubplazas = useSubplazaQueryAction("filter", {});
  const destroySubplaza = useSubplazaMutationAction("destroy");

  const toast = useToast();

  const addSubplaza = () => setAction("create");
  const showSubplazas = () => setAction("show");
  const editSubplaza = (subplaza: Subplaza) => {
    setSelectedSubplaza(subplaza);
    setAction("edit");
  };

  const onCreatedSubplaza = () => {
    toast({
      status: "success",
      duration: 2000,
      title: "Sub-praça criada com sucesso!",
    });
    showSubplazas();
  };

  const onUpdateSubplaza = () => {
    toast({
      status: "success",
      duration: 2000,
      title: "Sub-praça atualizada com sucesso!",
    });
    showSubplazas();
  };

  const addButton = (
    <HStack alignSelf="flex-start">
      <Button as={Link} role="a" size="lg" color="white" onClick={addSubplaza}>
        Adicionar
      </Button>
    </HStack>
  );

  const editButton = (subplaza: Subplaza) => (
    <Button size="sm" mr={2} onClick={() => editSubplaza(subplaza)}>
      Editar
    </Button>
  );

  const removeButton = (subplaza: Subplaza) => (
    <Button
      colorScheme="red"
      size="sm"
      onClick={() => destroySubplaza[0](subplaza)}
    >
      Arquivar
    </Button>
  );

  const editScreen = (
    <VStack gap={2} w="full" alignItems="flex-start">
      <HStack gap={8}>
        <Button onClick={showSubplazas}>
          <CaretLeft weight="bold" size={24} />
        </Button>
        <Heading fontWeight="extrabold" textAlign="left">
          Editar Subpraça
        </Heading>
      </HStack>
      <Flex
        flexDir="column"
        justifyContent="space-between"
        alignItems="flex-start"
        bg="whiteAlpha.800"
        w="full"
        h="full"
        p={8}
      >
        <SubplazaFormEdit
          subplaza={selectedSubplaza as Subplaza}
          onUpdate={onUpdateSubplaza}
        />
      </Flex>
    </VStack>
  );

  const createScreen = (
    <VStack gap={2} w="full" alignItems="flex-start">
      <HStack gap={8}>
        <Button onClick={showSubplazas}>
          <CaretLeft weight="bold" size={24} />
        </Button>
        <Heading fontWeight="extrabold" textAlign="left">
          Nova Subpraça
        </Heading>
      </HStack>
      <Flex
        flexDir="column"
        justifyContent="space-between"
        alignItems="flex-start"
        bg="whiteAlpha.800"
        w="full"
        h="full"
        p={8}
      >
        <SubplazaFormCreate onCreated={onCreatedSubplaza} />
      </Flex>
    </VStack>
  );

  const rowActions = (subplaza: Subplaza) => {
    return (
      <Box>
        {editButton(subplaza)}
        {removeButton(subplaza)}
      </Box>
    );
  };

  return (
    <div>
      {action === "create" ? (
        createScreen
      ) : action === "edit" ? (
        editScreen
      ) : (
        <GenericList
          dataQuery={filterSubplazas}
          beforeTable={addButton}
          rowActions={rowActions as (data: unknown) => React.ReactNode}
          actionsLabel="Ações"
          columns={columns}
        />
      )}
    </div>
  );
}
