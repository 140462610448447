import React from "react";
import ReactDOM from "react-dom/client";
import { ChakraProvider } from "@chakra-ui/react";
import theme from "./styles/theme/index.ts";
import App from "./App.tsx";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import * as Sentry from "@sentry/react";

Sentry.init({
  dsn: "https://aba9146255dea788906d718fc4c09463@o4507974608027648.ingest.us.sentry.io/4507975209451520",
  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.replayIntegration(),
  ],
  // Tracing
  tracesSampleRate: 1.0, //  Capture 100% of the transactions
  // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
  tracePropagationTargets: [
    "localhost",
    "atendimento.trampay.com",
    "empresa.trampay.com",
    "atendimento-hml.trampay.com",
    "empresa-hml.trampay.com",
  ],
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});

export const queryClient = (() => {
  const client = new QueryClient();
  return {
    value() {
      return client;
    },
    checkReference(newClient: QueryClient) {
      return client === newClient;
    },
  };
})();

ReactDOM.createRoot(document.getElementById("root") as HTMLElement).render(
  <React.StrictMode>
    <QueryClientProvider client={queryClient.value()}>
      <ChakraProvider
        theme={theme}
        toastOptions={{ defaultOptions: { isClosable: true } }}
      >
        <App />
      </ChakraProvider>
      <ReactQueryDevtools position="bottom-left" />
    </QueryClientProvider>
  </React.StrictMode>
);
