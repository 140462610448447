import { Heading, Text, VStack } from "@chakra-ui/layout";

export function RegisterDelvierymanTab() {
  return (
    <VStack w="full" h="full" gap={6} p={8} borderRadius={8}>
      <Heading fontSize="3xl" textAlign="left">
        Cadastramento de entregadores
      </Heading>
      <Text fontSize="lg" textAlign="left">
        Em breve atualizações.
      </Text>
      <iframe
        width="800px"
        height="500px"
        src=""
        title="YouTube video player"
        frameBorder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
      ></iframe>
    </VStack>
  );
}
