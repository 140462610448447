import { Box, Flex, HStack, Heading, VStack } from "@chakra-ui/layout";
import { Button, useToast } from "@chakra-ui/react";
import { Link } from "react-router-dom";
import { GenericList } from "../../../../components/scales/generic-list";
import { PlazaFormCreate } from "./create-one";
import { ReactNode, useState } from "react";
import { CaretLeft } from "@phosphor-icons/react";
import { usePlazaCachedService } from "../../../../services/api/plaza";
import { PlazaFormEdit } from "./edit";
import { IPlazaSetting, Plaza, textSchemeMap } from "../../../../dto/plaza-dto";
import { format, formatDate } from "../../../../utils/format-date";
import { acceptanceEnum } from "../../../../enums/acceptance.enum";

export function Plazas() {
  const [action, setAction] = useState<string>("show");
  const [selectedPlaza, setSelectedPlaza] = useState<Plaza>();

  const formatCreatedAt = (row: Record<string, unknown>) =>
    formatDate(row, "createdAt", format.BR, "/");
  const formatUpdatedAt = (row: Record<string, unknown>) =>
    formatDate(row, "updatedAt", format.BR, "/");

  const getAcceptanceType = (row: Record<string, unknown>) => {
    const setting = row?.setting as IPlazaSetting;
    return textSchemeMap[
      (setting?.acceptance || acceptanceEnum.MANUALLY) as acceptanceEnum
    ];
  };

  const columns = [
    { header: "ID", accessor: "id" },
    { header: "Nome", accessor: "name" },
    {
      header: "Aceite",
      accessor: "setting.acceptance",
      dataTransform: getAcceptanceType,
    },
    {
      header: "Data de criação",
      accessor: "createdAt",
      dataTransform: formatCreatedAt,
    },
    {
      header: "Última atualização",
      accessor: "updatedAt",
      dataTransform: formatUpdatedAt,
    },
  ];

  const { useQueryAction, useMutationAction } = usePlazaCachedService();

  const toast = useToast();

  const filterPlazas = useQueryAction("filter", {});
  const [destroy] = useMutationAction("destroy");

  const addPlaza = () => setAction("create");
  const showPlazas = () => setAction("show");
  const editPlaza = (plaza: Plaza) => {
    setSelectedPlaza(plaza);
    setAction("edit");
  };

  const destroyPlaza = (plaza: Plaza) => {
    destroy(plaza);
  };

  const onCreatedPlaza = () => {
    toast({
      status: "success",
      duration: 2000,
      title: "Praça criada com sucesso!",
    });
    showPlazas();
  };

  const onUpdatePlaza = () => {
    toast({
      status: "success",
      duration: 2000,
      title: "Praça atualizada com sucesso!",
    });
    showPlazas();
  };

  const addButton = (
    <HStack alignSelf="flex-start">
      <Button as={Link} role="a" size="lg" color="white" onClick={addPlaza}>
        Adicionar
      </Button>
    </HStack>
  );

  const editButton = (plaza: Plaza) => (
    <Button size="sm" mr={2} onClick={() => editPlaza(plaza)}>
      Editar
    </Button>
  );

  const removeButton = (plaza: Plaza) => (
    <Button colorScheme="red" size="sm" onClick={() => destroyPlaza(plaza)}>
      Arquivar
    </Button>
  );

  const editScreen = (
    <VStack gap={2} w="full" alignItems="flex-start">
      <HStack gap={8}>
        <Button onClick={showPlazas}>
          <CaretLeft weight="bold" size={24} />
        </Button>
        <Heading fontWeight="extrabold" textAlign="left">
          Editar Praça
        </Heading>
      </HStack>
      <Flex
        flexDir="column"
        justifyContent="space-between"
        alignItems="flex-start"
        bg="whiteAlpha.800"
        w="full"
      >
        <PlazaFormEdit
          plaza={selectedPlaza as Plaza}
          onUpdate={onUpdatePlaza}
        />
      </Flex>
    </VStack>
  );

  const createScreen = (
    <VStack gap={2} w="full" alignItems="flex-start">
      <HStack gap={8}>
        <Button onClick={showPlazas}>
          <CaretLeft weight="bold" size={24} />
        </Button>
        <Heading fontWeight="extrabold" textAlign="left">
          Nova Praça
        </Heading>
      </HStack>
      <Flex
        flexDir="column"
        justifyContent="space-between"
        alignItems="flex-start"
        bg="whiteAlpha.800"
        w="full"
        h="full"
        p={8}
      >
        <PlazaFormCreate onCreated={onCreatedPlaza} />
      </Flex>
    </VStack>
  );

  const rowActions = (plaza: Plaza): ReactNode => {
    return (
      <Box>
        {editButton(plaza)}
        {removeButton(plaza)}
      </Box>
    );
  };

  return (
    <div>
      {action === "create" ? (
        createScreen
      ) : action === "edit" ? (
        editScreen
      ) : (
        <GenericList
          dataQuery={filterPlazas}
          beforeTable={addButton}
          rowActions={rowActions as (data: unknown) => ReactNode}
          actionsLabel="Ações"
          columns={columns}
        />
      )}
    </div>
  );
}
