/* eslint-disable prettier/prettier */
/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { useState, useEffect } from "react";
import { VStack, Heading, Flex, Box, Stack } from "@chakra-ui/layout";
import {
  Button,
  Tbody,
  Th,
  Thead,
  Tr,
  useToast,
  Table,
  Td,
  NumberInput,
  NumberInputField,
  Input,
  Stat,
  StatLabel,
  StatNumber,
  UseStepsReturn,
  FormControl,
  FormLabel,
  Select,
  Tooltip,
  RadioGroup,
  Radio,
} from "@chakra-ui/react";
import { convertCentsToReais } from "../../../../utils/convert-money";
import { FloppyDiskBack, Info, Question } from "@phosphor-icons/react";
import { Controller, useFieldArray, useForm } from "react-hook-form";

import { useCreatePayroll } from "../../../../store/payroll.store";
import { useCurrentUserStore } from "../../../../store/current-user.store";
import { AxiosError } from "axios";
import { useDebounce } from "../../../../hooks/enterprises/use-debounce";
import { PermissionsEnum } from "../../../../enums/permissions.enum";
import { useGetPayroll } from "../../../../hooks/payments/use-get-payroll";
import { PixKeyType } from "../../../../enums/pix-type";

type PaymentInputs = {
  payment: {
    document?: string;
    name?: string;
    description?: string;
    pix_type?: PixKeyType | "";
    pix_key?: string;
    amount?: number;
    status?: string;
    pixErrorMessage?: string;
  }[];
  isToValidatePixDocument: string;
};

type Props = UseStepsReturn;

export const ValidationStatus = {
  validated: "Validado",
  "missing information": "Faltando informações",
  "wrong amount": "Valor inválido",
  "missing pix info": "Faltando informação de pix",
  "invalid pix": "Pix inválido",
};

export function EditPaymentsToReprocess(props: Readonly<Props>) {
  const [searchFilter, setSearchFilter] = useState<string | undefined>();
  const debouncedSearch = useDebounce<string | undefined>(searchFilter, 200);
  const userPermissions = useCurrentUserStore((state) => state.permissions);
  const saveDraft = useCreatePayroll((state) => state.savePayrollDraft);
  const validatePayroll = useCreatePayroll((state) => state.validatePayroll);
  const validationResponse = useCreatePayroll(
    (state) => state.transactionValidated
  );

  const changePayrollStatus = useCreatePayroll(
    (state) => state.changePayrollStatus
  );
  const toast = useToast();

  const { isLoading, payrollCreated, reprocessedPayroll } = useGetPayroll();

  const {
    register,
    control,
    formState: { isSubmitting },
    handleSubmit,
    watch,
    trigger,
  } = useForm<PaymentInputs>({
    defaultValues: {
      payment: validationResponse?.result.map((item) => ({
        document: item.document,
        name: item.name,
        description: item.description,
        pix_key: item.pixKey,
        pix_type: item.pixType,
        amount: item.amountInCents / 100,
        status: item.status,
        pixErrorMessage: item.pixErrorMessage,
      })),
      isToValidatePixDocument:
        reprocessedPayroll?.isToValidatePixDocument === undefined ||
        reprocessedPayroll?.isToValidatePixDocument === null
          ? "true"
          : JSON.stringify(reprocessedPayroll?.isToValidatePixDocument),
    },
  });

  const { fields, append, remove } = useFieldArray({
    control,
    name: "payment",
  });

  const payments = watch("payment");
  const isToValidatePixDocument = watch("isToValidatePixDocument");

  const amount = payments?.reduce(
    (acc, field) => (acc += field.amount || 0),
    0
  );

  const filteredFields = debouncedSearch
    ? payments.filter(
        (field) =>
          field.name?.toLowerCase().includes(debouncedSearch.toLowerCase()) ||
          field.document?.includes(debouncedSearch)
      )
    : [];

  async function handleSave({
    payment,
    isToValidatePixDocument,
  }: Partial<PaymentInputs>) {
    if (!payrollCreated?.id || !payment) {
      return;
    }

    trigger();

    const payload = {
      payrollId: payrollCreated.id,
      isToValidatePixDocument: JSON.parse(isToValidatePixDocument as string),
      transactionDrafts: payment.map((payment) => ({
        document: payment.document!,
        name: payment.name!,
        description: payment.description!,
        pixType: payment.pix_type ? payment.pix_type : undefined,
        pixKey: payment.pix_key ? payment.pix_key : undefined,
        amountInCents: payment.amount ? Math.round(payment.amount * 100) : 0,
      })),
    };

    try {
      await saveDraft(payload);

      toast({
        status: "success",
        title: "O rascunho de reprocessamento salvo com sucesso",
        description:
          'Você pode consultar e retomar seus rascunhos pelo sub-menu lateral em "Folhas em rascunho"',
      });
    } catch (error) {
      console.error(error);
      if (error instanceof AxiosError) {
        if (
          error instanceof AxiosError &&
          (error.response?.status === 504 || error.response?.status === 524)
        ) {
          console.log("Erro de timeout do lado do servidor.");

          toast({
            status: "error",
            title: "Erro ao enviar folha para reprocessamento",
            description:
              "Não foi possível processar pela quantidade de dados. Tente novamente com planilhas menores",
          });
        }
        if (error.response?.status === 400) {
          return toast({
            status: "error",
            title: "Houve erro ao salvar",
            description:
              "Verifique se o tipo da chave pix foi preenchido corretamente em todas as transações.",
          });
        }
        return toast({
          status: "error",
          title: "Houve erro ao salvar",
          description: "Erro: " + error.response?.data.message,
        });
      }

      toast({
        status: "error",
        title: "Houve erro ao salvar",
        description: "Erro: " + JSON.stringify(error),
      });
    }
  }

  async function handleValidation(data: PaymentInputs) {
    changePayrollStatus(false);
    if (!payrollCreated?.id) {
      return;
    }

    const payload = {
      payrollId: payrollCreated.id,
      isToValidatePixDocument: JSON.parse(data.isToValidatePixDocument),
      transactionDrafts: data.payment.map((payment) => ({
        document: payment.document!,
        name: payment.name!,
        description: payment.description!,
        pixType: payment.pix_type ? payment.pix_type : undefined,
        pixKey: payment.pix_key ? payment.pix_key : undefined,
        amountInCents: payment.amount ? Math.round(payment.amount * 100) : 0,
      })),
    };

    try {
      const response = await validatePayroll(payload);
      if (!response) {
        return;
      }

      if (!response.hasError) {
        props.setActiveStep(2);
        return;
      }

      props.setActiveStep(1);

      toast({
        status: "error",
        title: "Há transações com erros",
        description:
          "Há transações com erros, por favor faça as correções necessárias",
      });

      const formattedDraft = response.result
        .map((item) => ({
          document: item.document,
          name: item.name,
          description: item.description,
          pix_type: item.pixType,
          pix_key: item.pixKey,
          amount: item.amountInCents / 100,
          status: item.status,
        }))
        .sort((a, b) => {
          if (a.status !== "validated" && b.status !== "validated") {
            return 0;
          }

          if (a.status !== "validated" && b.status === "validated") {
            return -1;
          }

          return 1;
        });
      remove();

      append(formattedDraft);
    } catch (error) {
      console.log(JSON.stringify(error));
      if (
        error instanceof AxiosError &&
        (error.response?.status === 504 || error.response?.status === 524)
      ) {
        console.log("Erro de timeout do lado do servidor.");

        toast({
          status: "error",
          title: "Erro ao enviar folha para reprocessamento",
          description:
            "Não foi possível processar pela quantidade de dados. Tente novamente com planilhas menores",
        });
      }
      if (error instanceof AxiosError) {
        return toast({
          status: "error",
          title: "Houve erro na validação",
          description: "Erro: " + error.response?.data.message,
        });
      }

      toast({
        status: "error",
        title: "Houve erro na validação",
        description: "Erro: " + JSON.stringify(error),
      });
    }
  }

  function handleSearch(index: number) {
    const item = payments[index];
    if (filteredFields.length === 0) {
      return !!searchFilter;
    }

    const itemNotFound = !filteredFields.some(
      (field) =>
        item.name?.toLowerCase() === field.name?.toLowerCase() ||
        item.document === field.document
    );

    return itemNotFound;
  }

  useEffect(() => {
    if (!payrollCreated) {
      props.goToPrevious();
    }
  }, [payrollCreated, props]);

  return (
    <VStack
      w="full"
      alignItems="flex-start"
      gap={4}
      id="container"
      style={{ containerType: "inline-size" }}
    >
      <Flex gap={4} w="full" flexDir={["column", "row"]} flexFlow="row wrap">
        <Flex gap={[4, 8]} flex={1}>
          <Box
            bg="whiteAlpha.800"
            border="1px"
            borderColor="gray.300"
            borderRadius={8}
            p={4}
            w="max-content"
            h="full"
            shadow="base"
          >
            <Stat>
              <StatLabel fontSize={["md", "xl"]}>Valor total: </StatLabel>
              <StatNumber fontSize={["xl", "3xl"]} color="green.500">
                {amount
                  ? convertCentsToReais(amount * 100)
                  : convertCentsToReais(0)}
              </StatNumber>
            </Stat>
          </Box>
          <Box
            bg="whiteAlpha.800"
            border="1px"
            borderColor="gray.300"
            borderRadius={8}
            p={4}
            w="max-content"
            h="full"
            shadow="base"
          >
            <Stat>
              <StatLabel fontSize={["md", "xl"]}>
                Quantidade de saldos:
              </StatLabel>
              <StatNumber fontSize={["xl", "3xl"]} color="green.500">
                {fields.length}
              </StatNumber>
            </Stat>
          </Box>
        </Flex>
        <Flex
          gap={4}
          flex={1}
          flexDir={["column", "row"]}
          justifyContent="flex-end"
          alignItems={["center", "flex-end"]}
        >
          {fields.length > 0 ? (
            <Tooltip label="Salvar como rascunho" aria-label="A tooltip">
              <Button
                size="lg"
                w={["full", "auto"]}
                _hover={{
                  bg: "blue.600",
                  color: "white",
                }}
                onClick={async () => {
                  await handleSave({
                    payment: payments,
                    isToValidatePixDocument,
                  });
                }}
                gap={2}
                bg="blue.400"
                color="white"
              >
                <FloppyDiskBack size={24} />
                Salvar
              </Button>
            </Tooltip>
          ) : null}

          {userPermissions?.some(
            (permission) =>
              permission.key === PermissionsEnum["Aprovar Split de Pagamento"]
          ) ? (
            <Button
              size="lg"
              w={["full", "auto"]}
              color="white"
              _hover={{
                bg: "green.600",
              }}
              type="submit"
              form="create-payroll-draft-form"
              isDisabled={isSubmitting}
              isLoading={isSubmitting}
              loadingText="Finalizando..."
            >
              Finalizar transações
            </Button>
          ) : null}
        </Flex>
      </Flex>

      <Flex
        w="full"
        justifyContent="space-between"
        alignItems="center"
        paddingX="50"
        as="form"
      >
        <FormControl w="50%">
          <Heading size="md">Filtros</Heading>
          <FormLabel>Pesquisa</FormLabel>

          <Input
            placeholder="Procure por nome ou documento..."
            bg="gray.100"
            value={searchFilter}
            w="full"
            maxW="3xl"
            onChange={(e) => setSearchFilter(e.currentTarget.value)}
          />
        </FormControl>
        <FormControl w="fit-content">
          <FormLabel display="flex" gap="12px">
            Validar documento Pix
            <Tooltip
              hasArrow
              textAlign="center"
              fontSize="md"
              label="Validar se o CPF/CNPJ passado é igual ao documento do dono da chave PIX"
              bg="gray.300"
              color="black"
            >
              <Question size={22} />
            </Tooltip>
          </FormLabel>
          <Controller
            name="isToValidatePixDocument"
            control={control}
            render={({ field: { onChange, value } }) => (
              <RadioGroup onChange={onChange} value={value}>
                <Stack direction="row">
                  <Radio value="true" colorScheme="green">
                    Sim
                  </Radio>
                  <Radio value="false" colorScheme="red">
                    Não
                  </Radio>
                </Stack>
              </RadioGroup>
            )}
          />
        </FormControl>
      </Flex>

      <Box
        overflowX="auto"
        display="flex"
        flexDir="column"
        w="full"
        gap={4}
        as="form"
        id="create-payroll-draft-form"
        onSubmit={handleSubmit(handleValidation)}
      >
        <Table variant="simple" colorScheme="green" w="full">
          <Thead>
            <Tr>
              <Th textAlign="center" minW="max-content">
                CPF/CNPJ
              </Th>
              <Th textAlign="center">Nome</Th>
              <Th textAlign="center">Descrição</Th>
              <Th textAlign="center" minW="max-content">
                Tipo da Chave Pix
              </Th>
              <Th textAlign="center">
                <Flex gap={2} justifyContent="center">
                  Chave Pix
                  <Tooltip
                    label="Deve inserir o número do celular com o DDD sem o zero a esquerda"
                    aria-label="Deve inserir o número do celular com o DDD sem o zero a esquerda tooltip"
                    placement="top"
                  >
                    <Info size={16} />
                  </Tooltip>
                </Flex>
              </Th>
              <Th textAlign="center">Valor (R$)</Th>
              <Th></Th>
            </Tr>
          </Thead>
          <Tbody>
            {!isLoading &&
              fields.map((item, index) => (
                <Tr key={item.id} hidden={handleSearch(index)}>
                  <Td>
                    <FormControl display="flex" gap={2} alignItems="center">
                      <Input
                        border="1px"
                        borderColor="gray.300"
                        bg="gray.100"
                        disabled={!!reprocessedPayroll?.schedulingDate}
                        w="150px"
                        {...register(`payment.${index}.document` as const)}
                      />
                    </FormControl>
                  </Td>
                  <Td>
                    <Input
                      border="1px"
                      borderColor="gray.300"
                      bg="gray.100"
                      w="150px"
                      disabled={!!reprocessedPayroll?.schedulingDate}
                      {...register(`payment.${index}.name` as const)}
                    />
                  </Td>
                  <Td>
                    <Input
                      border="1px"
                      borderColor="gray.300"
                      bg="gray.100"
                      w="150px"
                      disabled={Boolean(reprocessedPayroll?.schedulingDate)}
                      {...register(`payment.${index}.description` as const)}
                    />
                  </Td>
                  <Td>
                    <Select
                      {...register(`payment.${index}.pix_type` as const, {
                        deps: [`payment.${index}.pix_key`],
                      })}
                      w="100px"
                    >
                      <option value="">Nenhum</option>
                      <option value="cpf">CPF/CNPJ</option>
                      <option value="email">E-mail</option>
                      <option value="telefone">Telefone</option>
                      <option value="aleatorio">Aleatório</option>
                    </Select>
                  </Td>
                  <Td>
                    <FormControl display="flex" gap={2} alignItems="center">
                      <Input
                        border="1px"
                        borderColor="gray.300"
                        bg="gray.100"
                        w="150px"
                        {...register(`payment.${index}.pix_key` as const, {
                          required: false,
                          setValueAs(value) {
                            if (payments[index].pix_type === "cpf") {
                              value = value?.replace(/\D/g, "");
                              return value;
                            }
                            if (payments[index].pix_type === "telefone") {
                              if (value[0] === "+") {
                                return value;
                              }

                              return `+55${value}`.trim();
                            }
                            return value?.trim();
                          },
                        })}
                      />
                    </FormControl>
                  </Td>

                  <Td>
                    <FormControl display="flex" gap={2} alignItems="center">
                      <NumberInput
                        min={0}
                        display="flex"
                        gap={4}
                        flex={1}
                        minW="100px"
                        alignItems="center"
                        allowMouseWheel={false}
                        inputMode="decimal"
                        precision={2}
                        step={0.1}
                        isValidCharacter={(value) =>
                          /^[Ee0-9+\-.,]$/.test(value)
                        }
                      >
                        R$
                        <NumberInputField
                          textAlign="right"
                          border="1px"
                          borderColor="gray.300"
                          bg="gray.100"
                          type="number"
                          w="100px"
                          disabled={Boolean(reprocessedPayroll?.schedulingDate)}
                          {...register(`payment.${index}.amount` as const, {
                            valueAsNumber: true,
                            required: false,
                            min: {
                              value: 0.01,
                              message: "Insira um valor mínimo de R$ 0,01",
                            },
                          })}
                        />
                      </NumberInput>
                    </FormControl>
                  </Td>
                </Tr>
              ))}
          </Tbody>
        </Table>
      </Box>
    </VStack>
  );
}
