import { ReactNode, memo } from "react";

import { Box } from "@chakra-ui/layout";

import { Button } from "@chakra-ui/react";

const Component = ({
  row,
  publish,
  close,
  exportScaled,
  statuses,
}: {
  row: { id: number; status: any };
  publish: (row: Record<string, string | number>) => void;
  close: (row: Record<string, string | number>) => void;
  exportScaled: (row: Record<string, string | number>) => void;
  statuses: any;
}) => {
  let actionButtons: ReactNode;
  const { id } = row;

  const publishButton = (
    <Button
      size="sm"
      colorScheme="green"
      mr={2}
      onClick={() => publish({ id })}
    >
      Publicar
    </Button>
  );

  const closeButton = (
    <Button size="sm" colorScheme="red" mr={2} onClick={() => close({ id })}>
      Fechar
    </Button>
  );

  const exportButton = (
    <Button
      size="sm"
      colorScheme="yellow"
      mr={2}
      onClick={() => exportScaled({ shiftAssignmentId: id })}
    >
      Exportar
    </Button>
  );

  if (row.status === statuses.CREATED) {
    actionButtons = (
      <>
        {publishButton} {closeButton}
      </>
    );
  }

  if (row.status === statuses.PUBLISHED) {
    actionButtons = <>{closeButton}</>;
  }

  if (row.status === statuses.CLOSED) {
    actionButtons = <>{exportButton}</>;
  }

  return <Box>{actionButtons}</Box>;
};

export const RowActionsShiftAssignmentComponent = memo(Component);
