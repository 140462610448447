import { IPaginateUserDebtDTO } from "../../dto/user-debt-dto";
import { getWithAuth } from "../basicService";

const baseURL = "/api/v1/user_payment_debts";

const paginateFilter = async (
  filter: object
): Promise<IPaginateUserDebtDTO | null> => {
  const params = new URLSearchParams();
  Object.entries(filter).forEach(([key, value]) => {
    if (typeof value === "object") {
      params.set(key, JSON.stringify(value));
    } else {
      params.set(key, value.toString());
    }
  });

  const response = await getWithAuth(`${baseURL}`, {}, { params });

  return response?.data || null;
};

export const useUserPaymentDebtService = () => {
  return {
    paginateFilter,
  };
};
