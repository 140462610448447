import { TransactionStatus } from "../enums/transaction-status";
import { CompanyDto } from "./company-dto";

export type TransactionDto = {
  id: number;
  document: string;
  name: string;
  type: string;
  amount: number;
  pixKey: string;
  is_transaction_from_spreadsheet: boolean;
  uploadedFileId: string | null;
  companyId: number;
  status: TransactionStatus;
  createdAt: Date;
  updatedAt: Date;
  deletedAt: Date | null;
  errorMessage: string | null;
  pixType?: "email" | "telefone" | "aleatorio" | "cpf" | "";
  company: CompanyDto;
  account_id_from: number;
  account_id_to: number;
  transactionResponse: {
    transactionDate?: Date;
  };
  transactionDate?: Date;
};

export const transactionTextMap = {
  [TransactionStatus.ERROR]: "Erro",
  [TransactionStatus.PENDING]: "Pendente",
  [TransactionStatus.SUCCESS]: "Sucesso",
  [TransactionStatus.PROCESSING]: "Processando",
  [TransactionStatus.ROLLBACK]: "Rollback",
  [TransactionStatus.DENIED]: "Negado",
  [TransactionStatus.SCHEDULED]: "Agendado",
  [TransactionStatus.SCHEDULE_CANCELED]: "Agendamento cancelado",
  [TransactionStatus.APPROVED]: "Sucesso",
  [TransactionStatus.REVERSAL]: "Estorno",
  [TransactionStatus.NOT_EXECUTED]: "Não Processado",
};

export const transactionColorMap = {
  [TransactionStatus.ERROR]: "red",
  [TransactionStatus.PENDING]: "gray",
  [TransactionStatus.SUCCESS]: "green",
  [TransactionStatus.PROCESSING]: "gray",
  [TransactionStatus.ROLLBACK]: "red",
  [TransactionStatus.DENIED]: "red",
  [TransactionStatus.SCHEDULED]: "blue",
  [TransactionStatus.SCHEDULE_CANCELED]: "red",
  [TransactionStatus.APPROVED]: "green",
  [TransactionStatus.REVERSAL]: "gray",
  [TransactionStatus.NOT_EXECUTED]: "gray",
};
